import "../App.css";
import { Box } from "@mui/material";
import Hero from "../components/Hero/Hero";
import Header from "../components/Header/Header";
import Buy from "../components/Buy/Buy";
import Features from "../components/Features/Features";
import StartReading from "../components/StartReading/StartReading";
import Categories from "../components/Categories/Categories";
import Footer from "../components/Footer/Footer";
import FAB from "../components/FAB/FAB";
import axios from "axios";
import { useEffect, useState } from "react";
import TrendingAuthors from "../components/TrendingAuthors/TrendingAuthors";
import { variables } from "../theme/variables";
function Home() {
  const [appUrls, setAppUrls] = useState(null);
  const [topSellingBooks, setTopSellingBooks] = useState(null);
  const [categories, setCategories] = useState(null);
  const [topAuthors, setTopAuthors] = useState(null);
  const [policyText, setPolicyText] = useState(null);
  const [termsText, setTermsText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loading = () => {
    return (
      <iframe src="https://lottie.host/embed/aa84b7ec-eaae-4696-aa38-c49bb30e7eb9/TeCfY1mG0g.json"></iframe>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const appUrlsResponse = await axios.get("/api/reader/mobile-app-urls");
        setAppUrls(appUrlsResponse.data);

        const topSellingBooksResponse = await axios.get(
          "/api/book/top-selling"
        );
        setTopSellingBooks(topSellingBooksResponse.data);

        const categoriesResponse = await axios.get("/api/book/catalog-page");
        setCategories(categoriesResponse.data.subGenres);

        const topAuthorsResponse = await axios.get("/api/book/top-authors");
        setTopAuthors(topAuthorsResponse.data);
        const policy = await axios.get("/api/privacy-policy");
        setPolicyText(policy?.data);
        const terms = await axios.get("/api/terms-of-service");
        setTermsText(terms?.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [setIsLoading]);

  if (isLoading) {
    return (
      <Box
        bgcolor={variables.darkBlue}
        className="flex justify-center items-center h-screen"
      >
        {loading()}
      </Box>
    );
  }

  return (
    <Box>
      <Header />
      <Hero appUrls={appUrls} />
      <Buy />
      <Features />
      <StartReading topSellingBooks={topSellingBooks} />
      <Categories categories={categories} />
      <TrendingAuthors topAuthors={topAuthors} />
      <Footer policyText={policyText} termsText={termsText} />
      <FAB />
    </Box>
  );
}

export default Home;
