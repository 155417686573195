import React, { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import LogoEratu from "../../assets/images/eratu-logo-white.svg";
import HamburgerIcon from "../../assets/images/hamburger.svg";
import CloseIcon from "../../assets/images/Close.svg";
import { Box, Drawer, Typography } from "@mui/material";
import { scrollToElem } from "../../utils/utils";
import { variables } from "../../theme/variables";
import { headerLinks } from "../../utils/constant";

const Header = () => {
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <Box
        id="header"
        className="p-4 md:py-8 md:pl-20 md:pr-10 absolute w-full flex items-center justify-between"
      >
        <Box className="flex">
          <img className="w-7 md:w-10" src={Logo} alt="logo" />
          <img
            className="ml-6 w-14 md:w-[89px]"
            src={LogoEratu}
            alt="logo-eratu"
          />
        </Box>
        <Box className="hidden md:flex">
          {headerLinks.map((item, key) => (
            <Typography
              key={key}
              variant="body2"
              onClick={() => scrollToElem(item.href)}
              className="pl-6 cursor-pointer hover:text-[#EB5F78]"
            >
              {item.text}
            </Typography>
          ))}
        </Box>
        <Box className="flex md:hidden" onClick={() => setIsShowModal(true)}>
          <img src={HamburgerIcon} alt="hamburger" />
        </Box>
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: variables.darkBlue,
            },
          }}
          anchor="top"
          open={isShowModal}
          onClose={() => setIsShowModal(false)}
        >
          <Box className="flex justify-between p-4">
            <Box className="flex">
              <img width={28} src={Logo} alt="logo" />
              <img
                width={56}
                className="ml-6"
                src={LogoEratu}
                alt="logo-eratu"
              />
            </Box>
            <Box
              className="flex md:hidden"
              onClick={() => setIsShowModal(false)}
            >
              <img width={10} src={CloseIcon} alt="close" />
            </Box>
          </Box>
          <Box className="px-6 pt-6">
            {headerLinks.map((item, key) => (
              <Typography
                key={key}
                variant="body2"
                onClick={() => {
                  setIsShowModal(false);
                  scrollToElem(item.href);
                }}
                className="pb-6 cursor-pointer text-center hover:text-[#EB5F78]"
              >
                {item.text}
              </Typography>
            ))}
          </Box>
        </Drawer>
      </Box>
    </>
  );
};

export default Header;
