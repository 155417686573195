import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import FreeChapters from "./pages/FreeChapters";
import Home from "./pages/Home";
import FreeChaptersEpub from "./pages/FreeChaptersEpub";
import { theme } from "./theme/theme";

import { ThemeProvider } from "@mui/material/styles";
import PrivacyPolicy from "./pages/PrivacyPolicy";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/freeChapters" element={<FreeChapters />} />
        <Route path="/freeChaptersEpub" element={<FreeChaptersEpub />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
