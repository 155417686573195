// native smooth scrolling for Chrome, Firefox & Opera
// @see: https://caniuse.com/#feat=css-scroll-behavior
const nativeSmoothScrollTo = (elem) => {
  const topOffset = elem.getBoundingClientRect().top;
  window.scroll({
    behavior: "smooth",
    left: 0,
    top: topOffset + window.pageYOffset,
  });
};

// smooth scrolling stub
export const scrollToElem = (elemSelector) => {
  if (!elemSelector) {
    return;
  }

  const elem = document.querySelector(elemSelector);
  if (elem) {
    nativeSmoothScrollTo(elem);
  }
};
