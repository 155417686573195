import React from "react";
import { Box } from "@mui/material";
import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const SwiperWrapper = ({
  children,
  styles,
  direction,
  slidesPerView,
  slidesPerGroup,
  modules,
  delay,
  thumbs,
  onSwiper,
  navigation,
  freeMode,
  spaceBetween,
  watchSlidesProgress,
  loop,
  speed,
  centeredSlides,
}) => {
  return (
    <Box sx={styles?.wrapper}>
      <Swiper
        effect="slide"
        direction={direction}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween ?? 30}
        slidesPerGroup={slidesPerGroup}
        thumbs={thumbs}
        autoplay={delay ? { delay } : undefined}
        pagination={{
          clickable: true,
        }}
        modules={modules}
        className="mySwiper"
        style={styles?.swiper}
        loop={loop}
        speed={speed ?? 2000}
        onSwiper={onSwiper}
        navigation={navigation}
        freeMode={freeMode}
        watchSlidesProgress={watchSlidesProgress}
        centeredSlides={centeredSlides}
      >
        {children}
      </Swiper>
    </Box>
  );
};

export default SwiperWrapper;
