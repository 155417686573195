import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function FreeChapters() {
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const bookId = params.get("Id");
  const [chapters, setChapters] = useState([]);
  const [bookUrl, setBookUrl] = useState("");
  const [bookName, setBookName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/chapter/${bookId}/unlocked-chapters`
        );
        setChapters(response.data);

        const bookDescription = await axios.get(
          `/api/book/description/${bookId}`
        );
        setBookName(bookDescription?.data?.title);

        const bookUrlResponse = await axios.get(
          `/api/book/${bookId}/short-link`
        );
        if (bookUrlResponse.data.link) {
          setBookUrl(bookUrlResponse.data.link);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [bookId]);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  return (
    <>
      {isLoading ? (
        <div className="h-screen flex justify-center items-center">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="bg-white px-6">
          <h2 className="text-center mb-5">{bookName}</h2>
          {chapters.map((chapter) => (
            <div key={chapter.id}>
              <div dangerouslySetInnerHTML={{ __html: chapter.content }}></div>
            </div>
          ))}
          <div className="relative mt-24">
            {bookUrl && (
              <div className="fixed bottom-0 left-0 right-0 py-4 bg-white text-center">
                <div className="inline-block px-6 py-3 bg-[#F56A69] text-white rounded-lg shadow-lg">
                  {window.innerWidth <= 720 ? (
                    <Link to={bookUrl} target="_blank">
                      CONTINUE READING
                    </Link>
                  ) : (
                    <div>
                      <div className="cursor-pointer" onClick={toggleModal}>
                        CONTINUE READING
                      </div>
                      <div
                        id="static-modal"
                        className={`${
                          modalVisible ? "flex" : "hidden"
                        } fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50`}
                      >
                        <div className="relative p-4 w-full max-w-2xl max-h-full bg-white rounded-lg shadow-lg">
                          <div className="relative bg-white  ">
                            <div className="p-4 md:p-5 space-y-4">
                              <h2 className=" leading-relaxed text-gray-500 dark:text-gray-400">
                                Download Eratu app on your phone and continue
                                reading.
                              </h2>
                            </div>
                            <button
                              onClick={toggleModal}
                              type="button"
                              className="py-2.5 px-5 ms-3 text-sm font-medium text-white focus:outline-none bg-[#F56A69] rounded-lg border border-gray-200  "
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default FreeChapters;
