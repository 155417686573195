import { variables } from "./variables";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    // primary: {
    //   main: variables.primaryClolor,
    // },
    // secondary: {
    //   main: variables.secondaryColor,
    //   light: variables.lightBlue,
    // },
    // info: {
    //   main: variables.infoColor,
    // },
    // success: {
    //   main: variables.success,
    // },
    // warning: {
    //   main: variables.whiteColor,
    // },
  },
  typography: {
    fontFamily: `"Inter", sans-serif`,
    // subtitle1: {
    //   fontWeight: 400,
    //   fontSize: 16,
    //   letterSpacing: "0.15px",
    //   color: variables.infoColor,
    //   "@media only screen and (max-width: 1024px)": {
    //     fontSize: 14,
    //   },
    // },
    // subtitle2: {
    //   fontWeight: 400,
    //   fontSize: 12,
    //   letterSpacing: "0.15px",
    //   color: variables.infoColor,
    // },
    // body1: {
    //   fontWeight: 500,
    //   fontSize: 24,
    //   letterSpacing: "0.15px",
    //   color: variables.primaryClolor,
    //   "@media only screen and (max-width: 1024px)": {
    //     fontSize: 16,
    //   },
    //   "@media only screen and (max-width: 700px)": {
    //     fontSize: 14,
    //     fontWeight: 400,
    //   },
    // },
    // body2: {
    //   fontWeight: 400,
    //   fontSize: 20,
    //   color: variables.infoColor,
    //   cursor: "pointer",
    //   "&:hover": {
    //     color: variables.hoverBlueColor,
    //   },
    // },
    h2: {
      fontWeight: 600,
      fontSize: 40,
      color: variables.whiteColor,
      "@media only screen and (max-width: 800px)": {
        fontSize: 20,
        fontWeight: 700,
      },
    },
    h3: {
      fontWeight: 600,
      fontSize: 36,
      color: variables.secondaryColor,
      "@media only screen and (max-width: 800px)": {
        fontSize: 22,
        fontWeight: 500,
      },
    },
    h4: {
      fontWeight: 700,
      fontSize: 32,
      color: variables.primaryColor,
      textAlign: "center",
    },
    h5: {
      fontWeight: 400,
      fontSize: 24,
      color: variables.greyColor,
    },
    h6: {
      fontWeight: 500,
      fontSize: 22,
      color: variables.infoColor,
      "@media only screen and (max-width: 800px)": {
        fontSize: 15,
        fontWeight: 400,
      },
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 20,
      color: variables.greyColor,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 18,
      color: variables.darkGreyColor,
      "@media only screen and (max-width: 800px)": {
        fontSize: 15,
      },
    },
    body1: {
      fontSize: 26,
      fontWeight: 400,
      color: variables.whiteColor,
      lineHeight: "normal",
      "@media only screen and (max-width: 800px)": {
        fontSize: 18,
      },
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      color: variables.whiteColor,
      lineHeight: "normal",
      "@media only screen and (max-width: 800px)": {
        fontSize: 12,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 800,
      lg: 1024,
      xl: 1400,
    },
  },
  components: {
    //   MuiButton: {
    //     styleOverrides: {
    //       root: {
    //         fontFamily: "Mardoto",
    //         textTransform: "unset",
    //         letterSpacing: "0.15px",
    //         ":hover": {
    //           backgroundColor: variables.hoverBlueColor,
    //         },
    //         "&.Mui-disabled": {
    //           color: variables.whiteColor,
    //           backgroundColor: variables.diabledBtnColor,
    //         },
    //       },
    //     },
    //   },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          // ":hover": {
          //   backgroundColor: variables.yellow,
          // },
        },
      },
    },
    //   MuiMenuItem: {
    //     styleOverrides: {
    //       root: {
    //         fontSize: "16px",
    //       },
    //     },
    //   },
    //   MuiSelect: {
    //     styleOverrides: {
    //       select: {
    //         fontSize: "16px",
    //       },
    //     },
    //   },
    //   MuiFormLabel: {
    //     styleOverrides: {
    //       root: {
    //         fontSize: "16px",
    //       },
    //     },
    //   },
    //   MuiPaginationItem: {
    //     styleOverrides: {
    //       ellipsis: {
    //         border: "none",
    //       },
    //       page: {
    //         color: variables.infoColor,
    //       },
    //     },
    //   },
  },
});
