import React from "react";
import { Box, Typography } from "@mui/material";
import Ongoing_CompleteImage from "./assets/Ongoing_Complete.svg";
import { variables } from "../../theme/variables";

function Buy() {
  return (
    <Box bgcolor={variables.black} id="do" className="mt-[-1px]">
      <Box className="pt-20 px-[72px] flex flex-col md:flex-row items-center justify-center">
        <Typography
          sx={{ lineHeight: { xs: "26px", md: "56px" } }}
          variant="h2"
          fontWeight={400}
          className="max-w-[420px] md:max-w-[460px] pb-6 md:pb-0 text-center md:text-left"
        >
          Buy both <span className="font-semibold text-[#2E8CC1]">Ongoing</span>{" "}
          and <span className="font-semibold text-[#3AC02E]">Complete</span>{" "}
          eBooks… right on the app
        </Typography>
        <img src={Ongoing_CompleteImage} alt="ongoing-complete" />
      </Box>
    </Box>
  );
}

export default Buy;
