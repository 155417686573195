import React, { useEffect, useState } from "react";
import TopArrow from "../../assets/images/top-arrow.svg";
import { Box } from "@mui/material";
import { scrollToElem } from "../../utils/utils";
import { variables } from "../../theme/variables";

const FAB = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsVisible(scrollY > window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {isVisible && (
        <Box
          onClick={() => scrollToElem("#header")}
          sx={{
            background: variables.borderColor,
          }}
          className="w-10 h-10 rounded-3xl flex justify-center items-center cursor-pointer fixed right-10 top-[60%] z-30"
        >
          <img src={TopArrow} alt="arrow" />
        </Box>
      )}
    </>
  );
};

export default FAB;
