import React from "react";
import { Box, Typography } from "@mui/material";
import { specialData } from "../../utils/constant";
import { SwiperSlide } from "swiper/react";
import SwiperWrapper from "../SwiperWrapper/SwiperWrapper";
import { Pagination, Autoplay } from "swiper/modules";

function FeaturesSlider() {
  const styles = {
    wrapper: {
      "& .swiper-wrapper": {
        height: 550,
      },
      "& .swiper-pagination-bullet": {
        background: "#333142",
        opacity: "unset",
      },
      "& .swiper-pagination": {
        position: "absolute",
        bottom: 10,
      },
      "& .swiper-pagination-bullet-active": {
        background: "#FF407A",
        width: "32px",
        borderRadius: "12px",
      },
    },
  };
  return (
    <Box sx={styles.wrapper}>
      <SwiperWrapper
        effect="slide"
        slidesPerView={1}
        className="mySwiper"
        style={styles}
        loop={true}
        speed={3000}
        navigation={false}
        modules={[Pagination, Autoplay]}
        delay={5000}
      >
        {specialData.map((item, key) => {
          return (
            <SwiperSlide key={key}>
              <Box
                className="grid grid-cols-1 mt-4 px-4 pb-4 rounded-xl h-[490px]"
                sx={{
                  background: `linear-gradient(180deg, rgba(7,9,20,1) 0%, rgba(46,44,75,1) 100%)`,
                  border: "1px solid #1F1D31",
                }}
              >
                <Box className="flex justify-center">
                  <img
                    className="max-w-[260px] max-h-[260px]"
                    src={item.image}
                    alt="features"
                  />
                </Box>
                <Box
                  className={`flex flex-col justify-center ${
                    item.position === "left" ? "items-start" : "items-end"
                  }`}
                >
                  <Box className="max-w-[570px]">
                    <Typography className="pb-3" variant="h2" fontWeight={700}>
                      {item.title}
                    </Typography>
                    <Typography className="pb-5" variant="h6">
                      {item.subtitle}
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {item.after}
                      <span className=" text-primaryColor">{item.redText}</span>
                      {item.before}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
      </SwiperWrapper>
    </Box>
  );
}

export default FeaturesSlider;
