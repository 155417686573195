import React from "react";
import { Box, Typography } from "@mui/material";
import TopBackgroundImage from "../../assets/images/top-background.png";
import LogoEratu from "../../assets/images/logo-eratu.svg";
import AppleIcon from "../../assets/images/apple.svg";
import MarketIcon from "../../assets/images/market.svg";
import { variables } from "../../theme/variables";
import { Link } from "react-router-dom";

function Hero({ appUrls }) {
  return (
    <Box
      className="min-h-screen bg-cover bg-no-repeat bg-right flex flex-col justify-center items-center"
      sx={{
        backgroundImage: `url(${TopBackgroundImage})`,
      }}
    >
      <img
        className="w-[133px] md:w-[224px]"
        src={LogoEratu}
        alt="logo-eratu"
      />
      <Typography variant="h2" className="pt-6 md:pt-10 pb-4 px-11 text-center">
        Where stories of love come to life...
      </Typography>
      <Typography
        variant="subtitle2"
        className="max-w-[479px] text-center px-8"
      >
        A place for romance readers to find all their favorite books along with
        the features we’ve all been waiting for.
      </Typography>
      <Box className="flex mt-10">
        <Link to={appUrls?.appleAppStoreUrl} target="_blank">
          <Box
            className="flex px-2 md:px-6 py-2 mr-2 rounded-lg cursor-pointer"
            sx={{
              background: variables.btnGradient,
            }}
          >
            <img src={AppleIcon} alt="apple-icon" />
            <Box className="ml-3 md:ml-6">
              <Typography variant="body2">Available on the</Typography>
              <Typography variant="body1">App Store</Typography>
            </Box>
          </Box>
        </Link>
        <Link to={appUrls?.googlePlayUrl} target="_blank">
          <Box
            className="flex px-2 md:px-6 py-2 rounded-lg cursor-pointer"
            sx={{
              background: variables.btnGradient,
            }}
          >
            <img src={MarketIcon} alt="market-icon" />
            <Box className="ml-3 md:ml-6">
              <Typography variant="body2">GET IT ON</Typography>
              <Typography variant="body1">Google Play</Typography>
            </Box>
          </Box>
        </Link>
      </Box>
    </Box>
  );
}

export default Hero;
