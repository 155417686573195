const $primary = "#F9F9F9";
const $secondary =
  "linear-gradient(to right, rgba(57, 57, 126, 1), rgba(167, 167, 230, 1))";
const $white = "#fff";
const $black = "#070914";
const $grey = "#F2F2F2";
const $darkGrey = "#A5ACBA";
const $info = "#F8F8F8";
const $btnGradient =
  "linear-gradient(119.54deg, rgba(255, 255, 255, 0.2) -50.54%, rgba(255, 255, 255, 0) 99.68%)";
const $success = "#1C1A2D";
const $darkIndigo = "#2C273D";
const $border = "#333142";
const $textHover = "#EB5F78";
const $darkBlue = "#131323";

export const variables = {
  primaryColor: $primary,
  secondaryColor: $secondary,
  whiteColor: $white,
  greyColor: $grey,
  infoColor: $info,
  darkGreyColor: $darkGrey,
  btnGradient: $btnGradient,
  black: $black,
  success: $success,
  darkIndigo: $darkIndigo,
  borderColor: $border,
  textHover: $textHover,
  darkBlue: $darkBlue,
};
