import ImageSpiceLevels from "../components/Features/assets/Spice-Levels-for-Each-Book.webp";
import ImageTriggers from "../components/Features/assets/Triggers-are-Finally-Here.webp";
import ImageComment from "../components/Features/assets/Comment-on-Chapters.webp";
import ImageOrganize from "../components/Features/assets/Organize-Your-Books.webp";
import ImageTropes from "../components/Features/assets/Tropes-_-Tags.webp";
import ImageViolence from "../components/Features/assets/Violence-Levvels-for-Each-Book.webp";

export const specialData = [
  {
    position: "left",
    image: ImageSpiceLevels,
    title: "Spice Levels for Each Book",
    subtitle: "How would you rate the allure of a 0 to 5 Spice Level?",
    after: "Navigate Your Read with the ",
    redText: "0-5 Spice Level",
    before:
      ". Eratu authors use a consistent rating method, ensuring no unexpected fade-to-black or spice surprises.",
  },
  {
    position: "right",
    image: ImageTriggers,
    title: "Triggers are Finally Here",
    subtitle: "Have unexpected book content ever caught you off guard?",
    after: "",
    redText: "Uncover hidden triggers ",
    before: "safely below the blurb—avoid or indulge, the choice is yours.",
  },
  {
    position: "left",
    image: ImageComment,
    title: "Comment on Chapters",
    subtitle:
      "Did that chapter break your heart or make you want to scream at the main character?",
    after:
      "There are so many people going through the same. Now you can let each other know in the ",
    redText: "comment section!",
    before: "",
  },
  {
    position: "right",
    image: ImageOrganize,
    title: "Organize Your Books",
    subtitle: "Is your TBR pile growing by the day?",
    after: "In Eratu’s Library you have 3 sections: ",
    redText: "Current Reads, To Be Read and Shelved",
    before: ". Organize and easily find your books right here.",
  },
  {
    position: "left",
    image: ImageTropes,
    title: "Tropes & Tags",
    subtitle: "No more guessing or hunting for tropes.",
    after: "They are all right here with the blurb… and ",
    redText: "you can click",
    before: " on them to find more books you are in the mood for.",
  },
  {
    position: "right",
    image: ImageViolence,
    title: "Violence Levels for Each Book",
    subtitle: "Want to know the level of your favorite book? ",
    after: "We are here to provide just ",
    redText: "the right book",
    before: " for your current mood.",
  },
];

export const headerLinks = [
  { href: "#do", text: "What we do" },
  { href: "#features", text: "Our Special Features" },
  { href: "#categories", text: "Our Categories" },
  { href: "#authors", text: "Trending Authors" },
];
