import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { ReactReader } from "react-reader";
import { useLocation } from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function FreeChaptersEpub() {
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const bookId = params.get("Id");
  const [chapters, setChapters] = useState("");
  const [bookUrl, setBookUrl] = useState("");
  const [bookName, setBookName] = useState("");
  const [locationChapter, setLocationChapter] = useState(0);
  const rendition = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `/api/book/${bookId}/download-free-chapters`,
          {
            responseType: "arraybuffer",
          }
        );
        const bookDescription = await axios.get(
          `/api/book/description/${bookId}`
        );
        setBookName(bookDescription?.data?.title);
        const bufferData = response.data;
        const blob = new Blob([bufferData], {
          type: "application/octet-stream",
        });
        const url = URL.createObjectURL(blob);
        setChapters(url);
        const bookUrl = await axios.get(`/api/book/${bookId}/short-link`);
        if (bookUrl.data.link) {
          setBookUrl(bookUrl.data["link"]);
        }
      } catch (error) {
        console.error("Error fetching chapters:", error);
      }
    })();
  }, [bookId]);

  const getRendition = (_rendition) => {
    rendition.current = _rendition;
    rendition.current.hooks.content.register((contents) => {
      const body = contents.document.body;
      if (body) {
        body.style.columnCount = "1";
        body.style.columnGap = "0";
      }
    });
  };

  return (
    <div className="bg-white h-screen">
      <div className="h-[80%]">
        <ReactReader
          url={chapters}
          epubInitOptions={{
            openAs: "epub",
          }}
          location={locationChapter}
          locationChanged={(epubcfi) => setLocationChapter(epubcfi)}
          title={bookName}
          showToc={false}
          getRendition={getRendition}
        />
      </div>
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex justify-center items-end z-[9999]">
        <div className="p-11 w-[300px] h-[20%]  bg-transparent ">
          <a
            href={bookUrl}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "block",
              padding: "7px",
              width: "100%",
              backgroundColor: "#F56A69",
              borderRadius: "18px",
              fontSize: "17px",
              color: "white",
            }}
          >
            <p className="text-center">Continue Reading</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FreeChaptersEpub;
