import "../App.css";
import { Box } from "@mui/material";

import axios from "axios";
import { useEffect, useState } from "react";

function PrivacyPolicy() {
  const [policyText, setPolicyText] = useState(null);

  const styles = {
    wrapper: {
      "& ul": {
        listStyle: "inside",
      },
    },
  };

  useEffect(() => {
    axios
      .get("/api/privacy-policy")
      .then((response) => {
        setPolicyText(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Box sx={styles.wrapper}>
      {policyText ? (
        <div
          className="x"
          dangerouslySetInnerHTML={{ __html: policyText }}
        ></div>
      ) : null}
    </Box>
  );
}

export default PrivacyPolicy;
